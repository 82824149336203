<template>
  <div>
    <div class="box">
        <div class="box_left">
          <div class="window_btn" @click="closeWindow"><i class="iconfont icon-suoxiao"></i></div>
          <el-tabs v-model="activeName" class="top_tabs" v-if="courseWareList.length > 0 || course.question_num > 0">
            <el-tab-pane label="课件" name="courseware" v-if="courseWareList.length > 0 ? true : false">
              <TeachingCourseware :selected_index="coursewareSelected" :courseware_list="courseWareList" @getCourseWareSelectedIndex="getCourseWareSelectedIndex"></TeachingCourseware>
            </el-tab-pane>
            <el-tab-pane label="练习题" name="question" v-if="course.question_num > 0">
              <TeachingExercise :paper_ids="course.paper_ids" :cur_name="cur_name" @getAnswers="getAnswers" @getSubmit="getSubmit"></TeachingExercise>
            </el-tab-pane>
          </el-tabs>
        </div>
    </div>
  </div>
</template>

<script>
  import TeachingCourseware from 'views_f/course/includes/TeachingCourseware'
  import TeachingExercise from 'views_f/course/includes/TeachingExercise'
  import { init } from "api/course/course/study"

  export default {
    components: {
      TeachingCourseware,
      TeachingExercise
    },
    data() {
      return {
        coursewareSelected: 0,
        courseWareList: [],
        course: {},
        activeName: 'courseware',
        cur_name: '',
        type: '',
        cur_uuid: '',
        uuid: '',
        recordData: {
          code: 0,
          isSubmit: false,
          data: {}
        },
        recordCoursewareData: {
          code: 1,
          selected: ''
        },
      }
    },
    created() {
      window.opener.postMessage('opened', '*')

      this.clearSession()

      window.addEventListener('message', this.messageHandler)
      window.addEventListener('beforeunload', () => {
        if(!this.recordData.isSubmit) {
          window.opener.postMessage(JSON.parse(JSON.stringify(this.recordData)), '*')
        } else {
          window.opener.postMessage('noDataSend', '*')
        }
        window.opener.postMessage(JSON.parse(JSON.stringify(this.recordCoursewareData)), '*')
      })

    },
    mounted() {
      document.getElementsByTagName('body')[0].className = 'teaching_content_page';

      this.type = this.$route.params.type
      this.cur_uuid = this.$route.params.cur_uuid
      this.uuid = this.$route.params.uuid

      this.initPage()

    },
    methods: {
      closeWindow() {
        window.close()
      },
      getCourseWareSelectedIndex(val) {
        this.recordCoursewareData.selected = val
      },
      messageHandler(e) {
        if(e.data.code === 0) { //试题
          if(!sessionStorage.getItem('is_submit')) {    //未提交答案则保存数据
            sessionStorage.setItem('answers', JSON.stringify(e.data.data)) //保存数据
          }
        }
        if(e.data.code === 1) { //课件
          this.coursewareSelected = e.data.selected
        }
      },
      initPage(){
        const that = this
        if(this.uuid == undefined || this.uuid == '' || this.uuid == 'undefined'){
          return false;
        }
        let params = {'type': this.type, 'uuid': this.uuid, 'cur_uuid': this.cur_uuid}

        init(params).then(res => {
          if(res.code === 0){ console.log(res.data);
            let data = res.data
            that.cur_name = res.cur_name
            that.course = data

            if(res.courseware_list.length > 0){
              that.activeName = 'courseware'
            }
            else {
              that.activeName = 'question'
            }
            that.courseWareList = res.courseware_list

          }
        })
      },
      getAnswers(answers) {
          this.recordData.data = answers
      },
      getSubmit(data) {
        this.recordData.isSubmit = data
        if(data) {
          sessionStorage.setItem('is_submit', data)
        }
      },
      clearSession() {
        if(sessionStorage.getItem('answers')) {
          sessionStorage.removeItem('answers')
        }
      }
    }
  }
</script>

<style lang="scss">
  html, body {min-width:480px;min-height:480px;}
  .teaching_content_page {
    #chatBtn {
      display:none !important;
    }
  }
</style>

<style lang="scss" scoped>
  .box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    min-width: 480px;
    .box_left {
      position: absolute;
      top: 0;
      left: 0;
      right:0;
      bottom: 0;
      background: #fff;
      min-width: 400px;
      word-break: break-all;

      .window_btn {
        position: absolute;
        top: 13px;
        right: 15px;
        cursor: pointer;
        z-index: 1;

        > i {
          color: #999;
          font-size: 21px;
        }

        &:hover {
          > i {
            color: #666;
          }
        }
      }

      :deep(.top_tabs) {
        .el-tabs__header {
          margin-bottom: 0;

          .el-tabs__nav-wrap {
            padding-left: 15px;
            padding-right: 15px;

            .el-tabs__item {
              height: 50px;
              line-height: 50px;
            }

            &:after {
              height: 1px;
            }
          }
        }

        .el-tabs__content {
          position: absolute;
          top: 50px;
          bottom: 0;
          right: 0;
          left: 0;
          overflow-y: auto;
        }
      }
    }
  }
</style>
